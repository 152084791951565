<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>官方信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-loading="loading">
      <el-button type="primary" style="margin-bottom: 20px" @click="addPiazza()">新建信息</el-button>
      <el-table :data="tableData" border style="width: 100%; display: inline-block" :default-sort="{prop: 'studentNum'}">
        <el-table-column align="center" prop="id" label="id" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="image_list" label="图片" width="auto">
          <template slot-scope="scope">
        <div style="display: flex; flex-wrap: nowrap;">
          <div v-for="(image, index) in scope.row.image_list" :key="index" style="margin-right: 10px;">
            <el-image
                style="width: 100px; height: 100px"
                :src="image"
                :preview-src-list="[image]">
            </el-image>
          </div>
        </div>
      </template>
        </el-table-column>
        <el-table-column align="center" prop="value" label="内容" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="create_time" label="发布时间" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态" width="auto">
          <template slot-scope="scope">
            {{ scope.row.status === 0 ? '正常' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="simple_desc" label="操作" width="auto">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row.id,scope.row.status)" type="text" size="small">{{ scope.row.status === 0 ? '禁用' : '启用' }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                     :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>

    <el-dialog title="发布信息" :visible.sync="dialogVisible" width="50%">
      <el-form ref="add_piazza" label-width="80px" v-loading="loading">
        <el-form-item label="图片">
          <el-upload
              ref="uploadRef"
              class="avatar-uploader"
              action="#"
              :limit="9"
              list-type="picture-card"
              :auto-upload="false"
              :file-list="fileList"
              :before-remove="beforeRemove">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" style="width: 148px;height: 148px">
              <span class="el-upload-list__item-actions">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="文字">
          <el-input v-model="add_piazza_value"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" @click="submitConfir">确定</el-button>
    </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogaaVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      dialogImageUrl: '',
      dialogaaVisible: false,
      disabled: false,
      userName: window.sessionStorage.getItem("username"),
      formInline: {
        studentName: '',
        studentNum: ''
      },
      dialogDelete: false,
      loading: false,
      dialogVisible: false,
      tableData: '',
      // 当前页
      pageNum: 1,
      // 当前每页显示多少条数据
      pageSize: 10,
      total: 0,
      editinfo: '',
      add_piazza_value: ""
    }
  },
  created() {
    this.getuser()
  },
  methods: {
    handleRemove(file) {
      this.fileList = this.fileList.filter(item => item.uid !== file.uid);
      if (this.fileList.length < 9) {
        this.disabled = false;
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogaaVisible = true;
    },
    reset() {
      // 当前页
      this.pageNum = 1
      // 当前每页显示多少条数据
      this.pageSize = 10
      this.loading = true
      this.$refs.searchInput.resetFields()
      this.getuser()
    },
    addPiazza() {
      this.dialogVisible = true
    },
    async forbidden(id, status) {
      const {data: res} = await this.$http.post('bk_official_display', {'id': id, 'status': status}, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.getuser()
        return this.$message.success('禁用成功')
      }
    },
    async add_piazza(image_list, value) {
      const {data: res} = await this.$http.post('bk_add_official_information', {"image_list": image_list, "value": value})
      if (res.status === 200) {
        this.loading = false
        this.dialogVisible = false
        this.add_piazza_value= ''
        this.$refs.uploadRef.uploadFiles = ''
        this.getuser()
        return this.$message.success('发布成功')
      }
    },
    async submitConfir() {
      const imageList = this.$refs.uploadRef.uploadFiles
      let image_url = []
      console.log('3333', imageList)
      this.loading = true
      for (let i = 0; i < imageList.length; i++) {
        const {data: res} = await this.$http.post('bk_upload_image', imageList[i], {
          headers: {
            'Content-Type': 'multipart/form-data', // 告诉服务器发送的是 FormData 数据
          }
        })
        image_url.push(res.image_url)
      }
      console.log("image_url", image_url)
      this.add_piazza(image_url, this.add_piazza_value)
    },
    async handleClick(id, status) {
      console.log(id, status)
      const statusText = status === 0 ? '禁用' : '启用';
      this.$confirm(`此操作将${statusText}此条信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.forbidden(id, status)
        console.log("确定删除")
      }).catch(() => {
        console.log("取消删除")
      })
    },
    async getuser() {
      this.loading = true
      console.log("pageNum======>", this.pageNum)
      console.log("pageSize=====>", this.pageSize)
      this.tableData = []
      const {data: res} = await this.$http.post('bk_official_information', {
        "pageNum": this.pageNum,
        "pageSize": this.pageSize,
      }, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      } else {
        this.$message.error(res.msg)
      }

    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.getuser()
    },
    handleCurrentChange(newSize) {
      this.pageNum = newSize
      this.getuser()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 35px;
}


</style>
