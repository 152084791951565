<template>
  <div>
    <h3>
      欢迎用户：{{this.username}} 登陆本系统
      <span>
        </span>
      <p></p>
      <span>
        当前时间为：
        {{dateFormat(date)}}
      </span>
    </h3>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username:window.sessionStorage.getItem("username"),
      date: new Date()
    }
  },
  created() {
    this.GetUsername()
  },
  methods: {
    GetUsername() {
      if (!window.sessionStorage.getItem('username')) {
        this.$router.push('/')
      }
      this.username = sessionStorage.getItem('username')
    },
    dateFormat(time) {
      let date = new Date(time);
      let year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
      * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
      * */
      let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }
  },
  mounted() {
    //显示当前日期时间
    let _this = this// 声明一个变量指向Vue实例this，保证作用域一致
    this.timer = setInterval(() => {
      _this.date = new Date(); // 修改数据date
    }, 1000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  }
}
</script>

<style lang="less" scoped>

</style>
