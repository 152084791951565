<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>个人信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-loading="loading">

      <el-table :data="tableData" border style="width: 100%; display: inline-block" :default-sort="{prop: 'studentNum'}">
        <el-table-column align="center" prop="id" label="id" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="user_name" label="昵称" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="sex" label="性别" width="auto">
          <template slot-scope="scope">
            {{ scope.row.sex === 0 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="header_image" label="头像" width="auto">
          <template slot-scope="scope">
            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.header_image"
                :preview-src-list="[scope.row.header_image]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="age" label="年龄" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="height" label="身高" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="weight" label="体重" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="city" label="城市" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态" width="auto">
          <template slot-scope="scope">
            {{ scope.row.status === 0 ? '正常' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="simple_desc" label="操作" width="auto">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row.id,scope.row.status)" type="text" size="small">{{ scope.row.status === 0 ? '禁用' : '启用' }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                     :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>


    <el-dialog title="请输入新成绩" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="tableinfo" label-width="80px" :rules="studentFormRules" v-loading="loading">

      </el-form>
      <span slot="footer" class="dialog-footer">
	    <el-button @click="dialogVisible = false">取 消</el-button>
	    <el-button type="primary" @click="submitConfir">确 定</el-button>
	  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: window.sessionStorage.getItem("username"),
      formInline: {
        studentName: '',
        studentNum: ''
      },
      dialogDelete: false,
      loading: false,
      dialogVisible: false,
      tableData: '',
      // 当前页
      pageNum: 1,
      // 当前每页显示多少条数据
      pageSize: 10,
      total: 0,
      editinfo: '',
      tableinfo: {
        "chinsesRule": "",
        "englishRules": "",
        "smathematicsRules": ""

      }
    }
  },
  created() {
    this.getuser()
  },
  methods: {
    reset() {
      // 当前页
      this.pageNum = 1
      // 当前每页显示多少条数据
      this.pageSize = 10
      this.loading = true
      this.$refs.searchInput.resetFields()
      this.getuser()
    },

    async forbidden(id, status) {
      const {data: res} = await this.$http.post('bk_disable', {'id': id, 'status': status}, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.getuser()
        return this.$message.success('禁用成功')
      }
    },
    async submitConfir() {
      console.log(typeof this.tableinfo['chinsesRule'] === 'undefined')
      console.log(typeof this.tableinfo['englishRules'] === 'undefined')
      console.log(typeof this.tableinfo['smathematicsRules'] === 'undefined')
      this.loading = true
      if (typeof this.tableinfo['chinsesRule'] === 'undefined' || typeof this.tableinfo['englishRules'] === 'undefined' || typeof this.tableinfo['smathematicsRules'] === 'undefined') {
        this.loading = false
        return this.$message.error('分数不能为空')
      }
      if (this.tableinfo['chinsesRule'] > 100 || this.tableinfo['englishRules'] > 100 || this.tableinfo['smathematicsRules'] > 100) {
        this.loading = false
        return this.$message.error('分数不能超过100分')
      }
      if (this.tableinfo['chinsesRule'] < 0 || this.tableinfo['englishRules'] < 0 || this.tableinfo['smathematicsRules'] < 0) {
        this.loading = false
        return this.$message.error('分数不能低于0分')
      }
      const {data: res} = await this.$http.post('updateScore', {
        'studentNum': this.tableinfo['studentNum'],
        "subject": {
          '语文': this.tableinfo['chinsesRule'],
          '英文': this.tableinfo['englishRules'],
          '数学': this.tableinfo['smathematicsRules'],
        }
      })
      if (res.status === 200) {
        this.getuser()
        this.loading = false
        this.dialogVisible = false
        return this.$message.success(res.msg)
      }


    },
    async handleClick(id, status) {
      console.log(id, status)
      const statusText = status === 0 ? '禁用' : '启用';
      this.$confirm(`此操作将${statusText}此账号, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.forbidden(id, status)
        console.log("确定删除")
      }).catch(() => {
        console.log("取消删除")
      })
    },
    async getuser() {
      this.loading = true
      console.log("pageNum======>", this.pageNum)
      console.log("pageSize=====>", this.pageSize)
      this.tableData = []
      const {data: res} = await this.$http.post('bk_user', {
        "pageNum": this.pageNum,
        "pageSize": this.pageSize,
      }, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.tableData = JSON.parse(res.data)
        this.total = res.total
        this.loading = false
      } else {
        this.$message.error(res.msg)
      }

    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.getuser()
    },
    handleCurrentChange(newSize) {
      this.pageNum = newSize
      this.getuser()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 35px;
}
</style>
