<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>征信审核</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-loading="loading">
      <el-table :data="tableData" border style="width: 100%; display: inline-block">
        <el-table-column align="center" prop="id" label="id" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="user_name" label="昵称" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="pic_url" label="征信文件" width="auto">
          <template slot-scope="scope">
          <el-link :href="scope.row.pic_url" target="_blank">默认链接</el-link>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="approval_status" label="审核状态" width="auto">
          <template slot-scope="scope">
            <span v-if="scope.row.approval_status === 0">待审核</span>
            <span v-else-if="scope.row.approval_status === 1">审核通过</span>
            <span v-else-if="scope.row.approval_status === 2">审核拒绝</span>
            <span v-else>未知状态</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="create_time" label="提交时间" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="update_time" label="审核时间" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="simple_desc" label="操作" width="auto">
          <template slot-scope="scope">
            <div v-if="scope.row.approval_status === 0">
              <el-button @click="handleClick(scope.row.id, '通过',scope.row.user_name)" type="text" size="small">通过</el-button>
              <el-button @click="handleClick(scope.row.id, '拒绝', scope.row.user_name)" type="text" size="small">拒绝</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                     :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>


  </div>
</template>

<script>


export default {
  data() {
    return {
      tag: "",
      fileList: [],
      dialogImageUrl: '',
      dialogaaVisible: false,
      disabled: false,
      userName: window.sessionStorage.getItem("username"),
      formInline: {
        studentName: '',
        studentNum: ''
      },
      dialogDelete: false,
      loading: false,
      dialogVisible: false,
      tableData: '',
      // 当前页
      pageNum: 1,
      // 当前每页显示多少条数据
      pageSize: 10,
      total: 0,
      main_tag: '',
      add_tag: ""
    }
  },
  created() {
    this.getuser()
  },
  methods: {
    async audit_operation(id, status) {
      const {data: rep} = await this.$http.post('/bk_credit_review', {id: id, type: status})
      this.getuser()
      return this.$message.success(rep.msg)
    },
    async handleClick(id, status, name) {
      console.log(id, status, name)
      let audit_status;
      this.$confirm(`是否${status}${name}的征信审核?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (status === '通过') {
          audit_status = 1;
        } else if (status === '拒绝') {
          audit_status = 2;
        }
        this.audit_operation(id, audit_status)
        console.log("确定删除")
      }).catch(() => {
        console.log("取消删除")
      })
    },
    async getuser() {
      this.loading = true
      console.log("pageNum======>", this.pageNum)
      console.log("pageSize=====>", this.pageSize)
      this.tableData = []
      const {data: res} = await this.$http.post('bk_credit_review', {
        "type": "",
        "pageNum": this.pageNum,
        "pageSize": this.pageSize,
      }, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.tableData = res.data
        this.main_tag = res.main_tag
        this.total = res.total
        this.loading = false
      } else {
        this.$message.error(res.msg)
      }

    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.getuser()
    },
    handleCurrentChange(newSize) {
      this.pageNum = newSize
      this.getuser()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 35px;
}

</style>
