import Vue from 'vue'
import Router from 'vue-router'
import user from './components/user.vue'
import Home from './components/Home.vue'
import Welcome from './components/Welcome'
import Login from '@/components/Login'
import piazza from "@/components/piazza.vue";
import tag from "@/components/tag.vue";
import main_tag from "@/components/main_tag.vue";
import academic_qualification_review from "@/components/academic_qualification_review"
import credit_review from "@/components/credit_review";
import official_information from "@/components/official_information";
Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/', component: Login, children: [
                // {path: '/index', component: indexpage},
                // {path: '/Anchor_display', component: Anchor_display},
                // {path: '/1', component: studentInfo, meta: {title:'student_info'}},
                // {path: '/2', component: add_student},
                // {path: '/4', component: checkLog},
                // {path: '/3', component: updateStudent},
                // {path: '/5', component: querystatistics}
            ],
        },
        {
            path: '/home', component: Home, children: [
                {path: '/home', component: Welcome},
                {path:'/user', component: user},
                {path:'/piazza', component:piazza},
                {path:'/main_tag', component:main_tag},
                {path:'/tag', component:tag},
                {path:'/academic_qualification_review', component:academic_qualification_review},
                {path:'/credit_review', component:credit_review},
                {path:'/official_information', component:official_information}
            ]
        }
    ]
})

// 挂载路由导航
// router.beforeEach((to, from, next) => {
//     // to 将要访问的路径
//     // from 从哪个路径跳转而来
//     // next 放行
//     if (to.path === '/') return next();
//     const str = window.sessionStorage.getItem('username')
//     if (!str) return next('/')
//     next()
// })

export default router
