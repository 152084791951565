<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>广场信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-loading="loading">
      <el-table :data="tableData" border style="width: 100%; display: inline-block" :default-sort="{prop: 'studentNum'}">
        <el-table-column align="center" prop="id" label="id" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="user_name" label="昵称" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="sex" label="性别" width="auto">
          <template slot-scope="scope">
            {{ scope.row.sex === 0 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="image_url" label="图片" width="auto">
          <template slot-scope="scope">
            <div v-for="(index,key) in scope.row.image_url" :key="key">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="index.image_url"
                  :preview-src-list="[index.image_url]">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="writing" label="内容" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="create_time" label="发布时间" width="auto">
        </el-table-column>
        <el-table-column align="center" prop="status" label="状态" width="auto">
          <template slot-scope="scope">
            {{ scope.row.status === 0 ? '正常' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="simple_desc" label="操作" width="auto">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row.id,scope.row.status)" type="text" size="small">{{ scope.row.status === 0 ? '禁用' : '启用' }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                     :page-size="pageSize"
                     layout="total, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>


  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
      dialogImageUrl: '',
      dialogaaVisible: false,
      disabled: false,
      userName: window.sessionStorage.getItem("username"),
      formInline: {
        studentName: '',
        studentNum: ''
      },
      dialogDelete: false,
      loading: false,
      dialogVisible: false,
      tableData: '',
      // 当前页
      pageNum: 1,
      // 当前每页显示多少条数据
      pageSize: 10,
      total: 0,
      editinfo: '',
      add_piazza_value: ""
    }
  },
  created() {
    this.getuser()
  },
  methods: {
    handleRemove(file) {
      this.fileList = this.fileList.filter(item => item.uid !== file.uid);
      if (this.fileList.length < 9) {
        this.disabled = false;
      }
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogaaVisible = true;
    },
    reset() {
      // 当前页
      this.pageNum = 1
      // 当前每页显示多少条数据
      this.pageSize = 10
      this.loading = true
      this.$refs.searchInput.resetFields()
      this.getuser()
    },
    addPiazza() {
      this.dialogVisible = true
    },
    async forbidden(id, status) {
      const {data: res} = await this.$http.post('bk_disable', {'id': id, 'status': status}, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.getuser()
        return this.$message.success('禁用成功')
      }
    },
    async handleClick(id, status) {
      console.log(id, status)
      const statusText = status === 0 ? '禁用' : '启用';
      this.$confirm(`此操作将${statusText}此账号, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.forbidden(id, status)
        console.log("确定删除")
      }).catch(() => {
        console.log("取消删除")
      })
    },
    async getuser() {
      this.loading = true
      console.log("pageNum======>", this.pageNum)
      console.log("pageSize=====>", this.pageSize)
      this.tableData = []
      const {data: res} = await this.$http.post('bk_piazza', {
        "pageNum": this.pageNum,
        "pageSize": this.pageSize,
      }, {headers: {'username': window.sessionStorage.getItem('username')}})
      if (res.status === 200) {
        this.tableData = res.data
        this.total = res.total
        this.loading = false
      } else {
        this.$message.error(res.msg)
      }

    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.getuser()
    },
    handleCurrentChange(newSize) {
      this.pageNum = newSize
      this.getuser()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  margin-top: 35px;
}


</style>
